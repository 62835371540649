.font-title {
  font-family: "Seymour One", sans-serif;
}

a {
  cursor: pointer;
}

body {
  background-color: #000;
  color: #fff;
  font-family: "Quicksand", sans-serif;
}

.intro {
  background-image: url("./assets/images/bg-intro.png");
}

.loading-wrapper {
  display: none;
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.8); /* Black w/ opacity */
}

/* .loading-wrapper div {
  width: 100%;
} */

.loading-wrapper.show-loading {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.go2072408551 {
  max-width: 500px;
}

.css-df17o1 {
  justify-content: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.react-responsive-modal-modal {
  margin: 0 !important;
  padding: 0 !important;
}
